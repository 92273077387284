<template>
  <div class="subscription-card">
    <div class="skeleton-line" v-if="isLoading"></div>
    <div
      class="subscriptions-item"
      :class="{
        'subscription-selected': isActive,
        'code-applied': !isActive && isCodeValid,
      }"
      v-else
      @click="$emit('change-product', product)"
    >
      <div class="sub-name">
        <div>{{ product.name }}</div>
        <div class="best-value">
          <span v-if="product.promo_label">{{ product.promo_label }}</span>
        </div>
      </div>
      <div class="sub-price">{{ (product.price / 100) | currency }}</div>

      <div class="sub-description">
        <div class="card-subline">{{ product.first_subline }}</div>
        <div class="card-subline">{{ product.second_subline }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: Object,
    activeProduct: Object,
    isLoading: Boolean,
    isCodeValid: Boolean,
  },
  computed: {
    isActive() {
      return this.product.id === this.activeProduct.id;
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription-card {
  min-height: 130px;
  .skeleton-line {
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    height: 130px;
    width: 100%;
    &::after {
      @include animatedLine;
    }
  }
  .subscriptions-item {
    border: 1px solid #717171;
    border-radius: 8px;
    padding: 11px 23px 18px 17px;
    @include cursorPointer;
    &:hover {
      background: #171717;
    }
    @media screen and (max-width: $mobile) {
      padding: 21px 11px 22px 17px;
    }
    .sub-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        align-items: flex-start;
      }
      .best-value {
        height: 15px;
        text-transform: uppercase;
        color: #cf1019;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
      }
    }
    .sub-price {
      margin: 10px 0;
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      @media screen and (max-width: $mobile) {
        margin-bottom: 14px;
      }
    }
    .sub-description {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #717171;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media screen and (max-width: $mobile) {
        font-size: 11px;
      }

      .card-subline {
        height: 15px;
      }
    }
  }
  .subscription-selected {
    border: 3px solid #ffffff;
    padding: 9px 21px 16px 15px;
    background: #1e1e1e;
    @media screen and (max-width: $mobile) {
      padding: 19px 9px 20px 15px;
    }
  }
  .code-applied {
    opacity: 0.4;
  }
}
</style>